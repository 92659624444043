body {
  overscroll-behavior: contain;
}

@font-face {
  font-family: TeliaSans;
  src: url('assets/fonts/otf/TeliaSans-Regular.otf') format('embedded-opentype'),
    url('assets/fonts/woff2/TeliaSans-Regular.woff2') format('woff2'),
    url('assets/fonts/woff/TeliaSans-Regular.woff') format('woff'),
    url('assets/fonts/ttf/TeliaSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: TeliaSans;
  src: url('assets/fonts/otf/TeliaSans-Bold.otf') format('embedded-opentype'),
    url('assets/fonts/woff2/TeliaSans-Bold.woff2') format('woff2'),
    url('assets/fonts/woff/TeliaSans-Bold.woff') format('woff'),
    url('assets/fonts/ttf/TeliaSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: TeliaSans;
  src: url('assets/fonts/otf/TeliaSans-BoldItalic.otf')
      format('embedded-opentype'),
    url('assets/fonts/woff2/TeliaSans-BoldItalic.woff2') format('woff2'),
    url('assets/fonts/woff/TeliaSans-BoldItalic.woff') format('woff'),
    url('assets/fonts/ttf/TeliaSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: TeliaSans;
  src: url('assets/fonts/otf/TeliaSans-RegularItalic.otf')
      format('embedded-opentype'),
    url('assets/fonts/woff2/TeliaSans-RegularItalic.woff2') format('woff2'),
    url('assets/fonts/woff/TeliaSans-RegularItalic.woff') format('woff'),
    url('assets/fonts/ttf/TeliaSans-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: TeliaSans;
  src: url('assets/fonts/otf/TeliaSans-Medium.otf') format('embedded-opentype'),
    url('assets/fonts/woff2/TeliaSans-Medium.woff2') format('woff2'),
    url('assets/fonts/woff/TeliaSans-Medium.woff') format('woff'),
    url('assets/fonts/ttf/TeliaSans-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: TeliaSans;
  src: url('assets/fonts/otf/TeliaSans-MediumItalic.otf')
      format('embedded-opentype'),
    url('assets/fonts/woff2/TeliaSans-MediumItalic.woff2') format('woff2'),
    url('assets/fonts/woff/TeliaSans-MediumItalic.woff') format('woff'),
    url('assets/fonts/ttf/TeliaSans-MediumItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: TeliaSansHeading;
  src: url('assets/fonts/otf/TeliaSansHeading-Heading.otf')
      format('embedded-opentype'),
    url('assets/fonts/woff2/TeliaSansHeading-Heading.woff2') format('woff2'),
    url('assets/fonts/woff/TeliaSansHeading-Heading.woff') format('woff'),
    url('assets/fonts/ttf/TeliaSansHeading-Heading.ttf') format('truetype');
}
